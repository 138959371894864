import $ from 'jquery';


// Import only the Bootstrap components we need
//import { Util, Dropdown, Offcanvas, Popover } from 'bootstrap';
//import { Navbar } from 'bootstrap';

//Dependences

//import Swiper, {Navigation, Pagination} from 'swiper';


import '../scss/style.scss';

import {removecaritems} from './partials/remove-cart-item';
import {btnheaderbascet} from './partials/btnheaderbascet';

import './partials/searching';
import Swal from "sweetalert2";

const scrollbarWidth = window.innerWidth - document.body.clientWidth
let headerheight = document.querySelector('.main-header').offsetHeight;
document.body.style.setProperty("--scrollbarWidth", `${scrollbarWidth}px`)
document.body.style.setProperty("--headerheight", `${headerheight}px`)


$(document).ready(function() {

  $('.lemon-contact').on('click', function(e) {
    e.preventDefault();

    // Get service value from data-service attribute
    const serviceValue = $(this).data('service') || '';
    //  console.log('DEBUG - Service value from button:', serviceValue);

    // Get modal content via AJAX
    $.ajax({
      type: 'POST',
      url: ajax_url,
      data: {
        action: 'ajax_contact_partial_lemon'
      },
      success: function(response) {
        if (response.success) {
          // Show modal with the fetched content
          showContactForm(response.modal, serviceValue);
        }
      },
      error: function() {
        console.error('Error loading contact form');
      }
    });
  });

  /**
   * Show contact form modal
   */
  function showContactForm(modalContent, serviceValue) {
    // Create and display the modal with SweetAlert
    Swal.fire({
      html: modalContent,
      showConfirmButton: false,
      showCloseButton: true,
      customClass: {
        popup: 'lemon-modal'
      },
      didOpen: () => {
        // Set the service value in the hidden field
        //  console.log('DEBUG - Setting service value in form:', serviceValue);
        //   console.log('DEBUG - Service field exists:', $('.swal2-container #service').length > 0);
        $('.swal2-container #service').val(serviceValue);
        //   console.log('DEBUG - Service field value after setting:', $('.swal2-container #service').val());
      }
    });

    // Handle form submission
    $(document).on('click', '.lemon-contact-button', function(e) {
      e.preventDefault();

      // Prevent multiple submissions
      const $submitBtn = $(this);
      if ($submitBtn.prop('disabled')) {
        return;
      }

      // Disable button immediately
      $submitBtn.prop('disabled', true).html('Odosielam... <i class="icon-spinner"></i>');

      // Get form inputs
      const email = $('.swal2-container #email').val();
      const firstName = $('.swal2-container #first_name').val();
      const lastName = $('.swal2-container #last_name').val();
      const phone = $('.swal2-container #phone').val();
      const comment = $('.swal2-container #comment').val();
      const service = $('.swal2-container #service').val();
      // console.log('DEBUG - Form submission service value:', service);
      // console.log('DEBUG - Service field exists (on submit):', $('.swal2-container #service').length > 0);
      const privacyAgreement = $('.swal2-container #privacy-agreement').prop('checked') ? 1 : '';

      // Validate form
      let isValid = true;
      let errors = {};

      // Reset validation state
      $('.swal2-container .form-control').removeClass('is-invalid');
      $('.swal2-container .invalid-feedback').hide();

      // Check required fields
      if (!email) {
        isValid = false;
        errors.email = $('.swal2-container #email').siblings('.invalid-feedback').attr('data-text');
        $('.swal2-container #email').addClass('is-invalid').siblings('.invalid-feedback').show();
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        isValid = false;
        errors.email = 'Neplatný formát emailu';
        $('.swal2-container #email').addClass('is-invalid').siblings('.invalid-feedback').text('Neplatný formát emailu').show();
      }

      if (!firstName) {
        isValid = false;
        errors.first_name = $('.swal2-container #first_name').siblings('.invalid-feedback').attr('data-text');
        $('.swal2-container #first_name').addClass('is-invalid').siblings('.invalid-feedback').show();
      }

      if (!lastName) {
        isValid = false;
        errors.last_name = $('.swal2-container #last_name').siblings('.invalid-feedback').attr('data-text');
        $('.swal2-container #last_name').addClass('is-invalid').siblings('.invalid-feedback').show();
      }

      if (!phone) {
        isValid = false;
        errors.phone = $('.swal2-container #phone').siblings('.invalid-feedback').attr('data-text');
        $('.swal2-container #phone').addClass('is-invalid').siblings('.invalid-feedback').show();
      }

      if (!privacyAgreement) {
        isValid = false;
        errors.privacy_agreement = $('.swal2-container #privacy-agreement').siblings('.invalid-feedback').attr('data-text');
        $('.swal2-container #privacy-agreement').addClass('is-invalid').siblings('.invalid-feedback').show();
      }

      if (isValid) {
        // Submit form via AJAX
        $.ajax({
          type: 'POST',
          url: ajax_url,
          data: {
            action: 'ajax_contact_lemon',
            email: email,
            first_name: firstName,
            last_name: lastName,
            phone: phone,
            comment: comment,
            service: service,
            privacy_agreement: privacyAgreement
          },
          dataType: 'json',
          beforeSend: function() {
            // Show loading indicator - already handled above
          },
          success: function(response) {
            // Parse response if it's a string
            if (typeof response === 'string') {
              try {
                response = JSON.parse(response);
              } catch (e) {
                console.error('Error parsing response:', e);
              }
            }

            if (response.success) {
              // Show success message
              Swal.fire({
                html: '<div class="modal-content"><div class="modal-header"><h5 class="modal-title fs-5">Úspešne odoslané</h5></div><div class="modal-body"><p>Ďakujeme za vašu správu! Budeme vás kontaktovať čo najskôr.</p></div><div class="modal-footer"><a href="#" class="btn btn-primary btn-right-icon swal-close-btn">Zatvoriť <i class="icon-arrow-right"></i></a></div></div>',
                showConfirmButton: false,
                showCloseButton: true,
                customClass: {
                  popup: 'lemon-modal'
                }
              });

              // Attach click handler to close button
              $('.swal-close-btn').on('click', function(e) {
                e.preventDefault();
                Swal.close();
              });
            } else {
              // Show field-specific errors if they exist
              if (response.email || response.first_name || response.last_name || response.phone || response.privacy_agreement) {
                // Show error for each field
                if (response.email) {
                  $('.swal2-container #email').addClass('is-invalid').siblings('.invalid-feedback').text(response.email).show();
                }
                if (response.first_name) {
                  $('.swal2-container #first_name').addClass('is-invalid').siblings('.invalid-feedback').text(response.first_name).show();
                }
                if (response.last_name) {
                  $('.swal2-container #last_name').addClass('is-invalid').siblings('.invalid-feedback').text(response.last_name).show();
                }
                if (response.phone) {
                  $('.swal2-container #phone').addClass('is-invalid').siblings('.invalid-feedback').text(response.phone).show();
                }
                if (response.privacy_agreement) {
                  $('.swal2-container #privacy-agreement').addClass('is-invalid').siblings('.invalid-feedback').text(response.privacy_agreement).show();
                }

                // Reset submit button
                $('.lemon-contact-button').prop('disabled', false).html('Odoslať <i class="icon-arrow-right"></i>');
              } else {
                // Show general error message
                Swal.fire({
                  html: '<div class="modal-content"><div class="modal-header"><h5 class="modal-title fs-5">Chyba</h5></div><div class="modal-body"><p>' + (response.message || 'Nastala chyba pri odosielaní. Prosím, skúste neskôr.') + '</p></div><div class="modal-footer"><a href="#" class="btn btn-primary btn-right-icon swal-close-btn">Zatvoriť <i class="icon-arrow-right"></i></a></div></div>',
                  showConfirmButton: false,
                  showCloseButton: true,
                  customClass: {
                    popup: 'lemon-modal'
                  }
                });

                // Attach click handler to close button
                $('.swal-close-btn').on('click', function(e) {
                  e.preventDefault();
                  Swal.close();
                });
              }
            }

            // Reset button
            $('.lemon-contact-button').html('Odoslať <i class="icon-arrow-right"></i>').attr('disabled', false);
          },
          error: function() {
            // Reset button
            $('.lemon-contact-button').html('Odoslať <i class="icon-arrow-right"></i>').attr('disabled', false);

            // Show general error message
            Swal.fire({
              html: '<div class="modal-content"><div class="modal-header"><h5 class="modal-title fs-5">Chyba</h5></div><div class="modal-body"><p>Nastala chyba pri odosielaní. Prosím, skúste neskôr.</p></div><div class="modal-footer"><a href="#" class="btn btn-primary btn-right-icon swal-close-btn">Zatvoriť <i class="icon-arrow-right"></i></a></div></div>',
              showConfirmButton: false,
              showCloseButton: true,
              customClass: {
                popup: 'lemon-modal'
              }
            });

            // Attach click handler to close button
            $('.swal-close-btn').on('click', function(e) {
              e.preventDefault();
              Swal.close();
            });
          }
        });
      } else {
        // Reset submit button if validation fails
        $('.lemon-contact-button').prop('disabled', false).html('Odoslať <i class="icon-arrow-right"></i>');
      }
    });
  }

  /**
   * Form validation - Remove error highlighting on input
   */
  $(document).on('input', '.swal2-container .form-control', function() {
    const $this = $(this);

    if ($this.val() !== '') {
      $this.removeClass('is-invalid');
      $this.siblings('.invalid-feedback').hide();
    }
  });

  /**
   * Checkbox validation on change
   */
  $(document).on('change', '.swal2-container #privacy-agreement', function() {
    const $this = $(this);

    if ($this.prop('checked')) {
      $this.removeClass('is-invalid');
      $this.siblings('.invalid-feedback').hide();
    }
  });

  $(window).on('resize', function(){
    headerheight = document.querySelector('.main-header').offsetHeight;
    document.body.style.setProperty("--headerheight", `${headerheight}px`)
  })

  removecaritems();
  $('.hamburger.mobile-menu').on('click',function(e){
    e.preventDefault();
    //console.log('hamburger');
    $(this).toggleClass('is-active')
    $('.mobile-menu-wrap').toggleClass('show')
    $('.main-header').toggleClass('menu-show')
    //$('.main-header').toggleClass('open')
  })

  $('.megamenu > .has-children > .nav-link ').on('click',function(e){
    e.preventDefault();
    if( $(this).closest('.has-children').find('> .nav-link').hasClass('active')){
      $(this).closest('.has-children').find('> .nav-link').toggleClass('active');
      $(this).closest('.has-children').find('> .megamenu-submenu').toggle()  
    }else {
      $('.megamenu > .has-children > .megamenu-submenu').hide();
      $('.megamenu > .has-children > .nav-link').removeClass('active');
      $(this).closest('.has-children').find('> .nav-link').toggleClass('active');
      $(this).closest('.has-children').find('> .megamenu-submenu').toggle()  
    }
    
  })
  $(document).on('click', function(e) {
    if (event && event.target) {
      var container = $(".megamenu");
      if (!container.is(event.target) && !container.has(event.target).length) {
        $('.megamenu > .has-children > .megamenu-submenu').hide();
        $('.megamenu > .has-children > .nav-link').removeClass('active');
      }
    }
  })

  $('.mobile-menu-wrap .has-children > .nav-link, .mobile-menu-wrap .has-children > .show-submenu').on('click',function(e){
    e.preventDefault();
    $(this).closest('.has-children').find('> .show-submenu').toggleClass('active');
    $(this).closest('.has-children').find('> .nav-link').toggleClass('active');
    $(this).closest('.has-children').find('> .sub-menu').animate({
      height: "toggle",
      opacity: "toggle"
    }, 200 );
  })

  var scroll = 0;
  $(window).on('scroll',function(){
    let curscroll = $(window).scrollTop();
    if (curscroll > scroll){
      // downscroll code
      $('.main-header').removeClass('scrollup');
    } else {
      // upscroll code
      $('.main-header').addClass('scrollup');
    }
    if ((curscroll > 0) ) {
      $('.main-header').addClass('scroll');
    }else {
      $('.main-header').removeClass('scroll');
      $('.main-header').removeClass('scrollup');
        
    }
    scroll = curscroll;
  })


  $('.anime-scroll').on('click',function(e){
    e.preventDefault();
    let thishas = $(this).find('.nav-link').attr('href')
    $('html, body').animate({scrollTop: $(thishas).offset().top - 110}, 1000);
  })
  

  btnheaderbascet();

  if($('#moove_gdpr_cookie_info_bar').length) {
    let cookies = getCookie('moove_gdpr_popup');
    //console.log(cookies);
    if (cookies == null) {
      //console.log('jeeee');
      var timerID = setInterval(function() {
        if($('#moove_gdpr_cookie_info_bar').is(':visible')) {
          $('#moove_gdpr_cookie_info_bar .moove-gdpr-infobar-settings-btn').trigger('click');
          $('.gdpr_lightbox-wrap').removeAttr('data-gdpr_lightbox-close');
          clearInterval(timerID);
        }
  
        //console.log(timerID)
      }, 1000);
    }
  }

});


function setCookieTrial() {
  var d = new Date();
  d.setTime(d.getTime() + (2*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = "noticepopup=click;" + expires;
}


function setCookie(name,value,days) {
  var expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}
function eraseCookie(name) {
  document.cookie = name+'=; Max-Age=-99999999;';
}
