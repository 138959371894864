import $ from 'jquery';

export function btnheaderbascet() {
  $(document).on('click', function(e) {
    if (event && event.target) {
      var container = $(".cartbutton-wrap");
      if (!container.is(event.target) && !container.has(event.target).length) {
        $('.basket-items-popup-wrap').hide();
        $('.btn-basketitems').removeClass('open');
      }
    }
  })
  $('.btn-basketitems').on('click',function(e){
    e.preventDefault();
    $(this).toggleClass('open');
    $(this).closest('.cartbutton-wrap').find('.basket-items-popup-wrap').animate({
      height: "toggle",
      opacity: "toggle"
    }, 200 );
  })
}