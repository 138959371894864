import $ from 'jquery';

import {stickyCat} from './stickyCat';
//console.log(stickyCat)
//var localstickyCat = stickyCat;


export function checkounextbutton(stickyCat) {
  
  $('.checkout-steps-next-button').on('click',function(e){
    e.preventDefault();
    let step = $(this).attr('data-step');
    let thisform = $('.checkout');
    let isValid = true;
    $('.form-control').removeClass('is-invalid');

    $('.step2 .form-control, .step2 .form-check-input',$(thisform)).each(function() {
      if ($(this).is(":invalid")) {
        $(this).addClass('is-invalid')
        isValid = false;
      }
    });

    if (!isValid) {
      
      $('html, body').animate({
        scrollTop: $(".form-control:invalid",$(thisform)).offset().top - 150
      }, 300);  
      
      
    }else {
      $('.stepper-wrap .step-item').removeClass('active');
      $('.stepper-wrap .step-item.step'+step+'').addClass('active');
      $('.checkout-steps-wrap .step').removeClass('active');
      $('.checkout-steps-wrap .step.step'+step+'').addClass('active');
      $.each(stickyCat,function(index, value){
        stickyCat[index].updateSticky();
      })
      $('html, body').animate({scrollTop: 0}, 700);
    }



  })
}